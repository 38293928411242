<template>
  <b-modal
    ref="incomeModal"
    id="income_modal"
    hide-footer
    hide-header
    scrollable
  >
    <div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <h3 class="w-700">Create/Edit Income</h3>
      </div>
      <hr />
      <!--<b-form-group id="lblLoan" label="" label-for="txtLoan">
        <b-input-group>
          <b-form-input
            placeholder="Enter References Code"
            size="sm"
            v-model="searchParams"
            disabled
          ></b-form-input>
           <b-input-group-append>
            <b-button
              size="sm"
              variant="info"
              @click="searchLoan"
              :disabled="!isCreated"
              >Search</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <span class="p-font">{{ form.lend_by.full_name }}</span> 
      </b-form-group>-->
      <b-form-group id="lblAmount" label="" label-for="txtLoanAmount">
        <b-form-input
          class="form-control col-12"
          id="txtLoanAmount"
          size="sm"
          name="txtLoanAmount"
          :disabled="!isCreated"
          placeholder="Amount"
          type="number"
          v-model="$v.form.amount.$model"
          :state="validateState('amount')"
          aria-describedby="amount-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="amount-live-feedback">
          Amount is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="lblNote" label="Note" label-for="txtNote">
        <b-form-textarea
          class="form-control col-12"
          id="txtNote"
          size="sm"
          name="txtNote"
          :disabled="!isCreated"
          v-model="form.note"
        ></b-form-textarea>
      </b-form-group>
    </div>
    <b-button
      size="sm"
      variant="outline-danger"
      class="mr-1"
      @click="resetForm"
      :disabled="!isCreated"
      >RESET</b-button
    >
    <b-button
      type="submit"
      size="sm"
      variant="outline-primary"
      @click="onSubmit"
      >SUBMIT</b-button
    >
  </b-modal>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "income-form",
  data() {
    return {
      isCreated: true,
      form: {
        id: 0,
        reference_code: null,
        amount: null,
        transaction_type: 1
      }
    };
  },
  validations: {
    form: {
      amount: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.isCreated) {
        ApiService.post("accounts", this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
            this.$refs["incomeModal"].hide();
            this.$parent.callByComponent();
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      } else {
        // ApiService.post("accounts/" + this.form.id, this.form).then(
        //   ({ data }) => {
        //     if (data.isSuccess) {
        //       Swal.fire({
        //         icon: "success",
        //         text: "Request is submitted successfully",
        //         focusConfirm: true
        //       });
        //     } else {
        //       Swal.fire({
        //         icon: "error",
        //         text:
        //           "Request submittion failed, Try again or contact system admin",
        //         focusConfirm: true
        //       });
        //     }
        //   }
        // );
      }
    },
    resetForm() {
      this.form = {
        id: 0,
        reference_code: null,
        amount: null,
        transaction_type: 1
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    getIdFromChild(id) {
      this.$refs.incomeModal.show();
      if (id != 0) {
        this.isCreated = false;
        // ApiService.get("accounts/" + id).then(({ data }) => {
        //   this.isCreated = false;
        //   this.form = data.transaction;
        // });
      } else {
        this.isCreated = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.p-font,
.form-group {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
